import { getStatusBadgeColor, formatNumber } from '../../utils/common';
interface IProps {
  bars: any;
  customTooltipGoals?: boolean;
}

const Tracker = ({ bars, customTooltipGoals }: IProps) => {
  return (
    <div className="flex gap-[4px]">
      {' '}
      {/* Increased gap between bars */}
      {bars.map((bar, index) => (
        <div
          key={index}
          className={`relative flex-1 bg-gray-200 h-4 group ${
            bars.length === 1
              ? 'rounded-full'
              : index === 0
                ? 'rounded-l-full'
                : index === bars.length - 1
                  ? 'rounded-r-full'
                  : ''
          }`}
        >
          {/* Tooltip */}
          {customTooltipGoals ? (
            <div className="w-fit absolute -bottom-[110px] left-1/2 transform -translate-x-1/2 px-2 py-1 rounded hidden opacity-0 group-hover:block group-hover:opacity-100 transition-opacity duration-300 text-[10px] bg-white text-sm shadow-lg z-50">
              <div className="flex flex-col gap-1">
                <div
                  style={{ backgroundColor: bar.color }}
                  className={`w-full text-center px-[10px] py-[3px] font-[600] uppercase rounded whitespace-nowrap ${bar?.item?.status === 'not-started' ? 'text-[#000]' : 'text-[#FFF]'}`}
                >
                  {getStatusBadgeColor(bar?.item?.status)?.text}
                </div>
                <div className="flex gap-2 text-[12px]">
                  <div className="whitespace-nowrap w-[90px]">
                    Current Value:
                  </div>
                  <div>{formatNumber(bar?.item?.currentValue)}</div>
                </div>
                <div className="flex gap-2 text-[12px]">
                  <div className="whitespace-nowrap w-[90px]">Goal Value:</div>
                  <div>{formatNumber(bar?.item?.goalValue)}</div>
                </div>
                <div className="flex gap-2 text-[12px]">
                  <div className="whitespace-nowrap w-[90px]">Target Date:</div>
                  <div className="whitespace-nowrap">
                    {bar?.item?.targetDate}
                  </div>
                </div>
                <div className="flex gap-2 text-[12px]">
                  <div className="whitespace-nowrap w-[90px]">Date Range:</div>
                  <div className="whitespace-nowrap capitalize">
                    {bar?.item?.type}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-fit absolute -top-[30px] left-1/2 transform -translate-x-1/2 bg-gray-800 text-white px-2 py-1 rounded hidden opacity-0 group-hover:block group-hover:opacity-100 transition-opacity duration-300 text-[10px] text-center whitespace-nowrap z-40">
              {bar.label}
            </div>
          )}

          <div
            className="h-[16px] cursor-pointer"
            style={{
              backgroundColor: bar.color,
              borderRadius:
                bars.length === 1
                  ? '6px'
                  : index === 0
                    ? '6px 0 0 6px'
                    : index === bars.length - 1
                      ? '0 6px 6px 0'
                      : '0',
            }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default Tracker;
