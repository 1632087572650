import moment from 'moment';
import Icon from '../../assets/icons/SvgComponent';
import ImageIcon from '../../components/ImageIcon';
import Tooltip from '../../components/HelpTooltip';
import ToolTipV2 from '../../components/TooltipV2';
import { Badge } from '@tremor/react';
import MetricsChart from '../../components/Modals/Performance/metricsChart';
import { getColumnTitle, isStaging } from '../../utils';
import { getDeltaTypeAndColor } from '../../utils/performance';
import MetricView from '../../components/Performance/MetricView';
import { formatNumber } from '../../utils/common';

// Define the props for the generateColumns function
interface GenerateColumnsProps {
  sortingColumn: any;
  sortingDirection: any;
  editClientPerformance: (data) => void;
  handleRegeneratePerformance: (ids, type) => void;
  regenerating: boolean;
  generatedClientId: string;
  integration?: any;
}

// Function to generate columns
export const generateSeoColumns = ({
  sortingColumn,
  sortingDirection,
  editClientPerformance,
  handleRegeneratePerformance,
  regenerating,
  generatedClientId,
  integration,
}: GenerateColumnsProps) => [
  // name column
  {
    id: 'name',
    header: () => (
      <div className="w-[180px] sm:w-[15vw] border-r dark:border-gray-700 h-[40px] flex items-center">
        <div className="flex w-[180px] sm:w-[15vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Client</div>
          <div>
            {sortingColumn === 'name' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'client',
    cell: (props: any) => {
      return (
        <div
          className="w-[180px] sm:w-[15vw] flex justify-start items-center cursor-pointer border-r dark:border-gray-700 h-[70px]"
          onClick={() => {
            if (props?.getValue() !== undefined) {
              editClientPerformance(props?.row?.original);
            }
          }}
        >
          <div>
            <Tooltip content={props.getValue()?.name} position="right">
              <div className="truncate overflow-hidden text-sm max-w-[170px] sm:max-w-[15vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF]">
                {props.getValue()?.name}
              </div>
            </Tooltip>
            <div
              className={`text-left text-xs font-inter font-light text-[#405573] dark:text-gray-300 w-fit`}
            >
              {props.getValue()?.category_type === null
                ? 'N/A'
                : props.getValue()?.category_type}
            </div>
            {props.getValue()?.notes?.ga4 && (
              <Tooltip content={props.getValue()?.notes?.ga4} position="right">
                <div
                  className={`text-left text-xs font-inter font-light text-[#FF0000] w-fit max-w-[170px] sm:max-w-[15vw] truncate`}
                >
                  Google SEO: {props.getValue()?.notes?.ga4}
                </div>
              </Tooltip>
            )}
            {props.getValue()?.notes?.gsc && (
              <Tooltip content={props.getValue()?.notes?.gsc} position="right">
                <div
                  className={`text-left text-xs font-inter font-light text-[#FF0000] w-fit max-w-[170px] sm:max-w-[15vw] truncate`}
                >
                  Google Search Console: {props.getValue()?.notes?.gsc}
                </div>
              </Tooltip>
            )}
            {generatedClientId === props?.row?.original?.client.client?.id &&
              regenerating && (
                <div className="text-left text-xs font-inter font-light text-[#405573] blink-1">
                  Generating...
                </div>
              )}
          </div>
        </div>
      );
    },
  },
  // revenue column
  {
    id: 'revenue',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Retainer</div>
          {sortingColumn === 'revenue' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'revenue',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex justify-center font-inter font-[400] text-sm text-[#001C44] dark:text-gray-300">
          {props.getValue()}
        </div>
      </div>
    ),
  },
  // account manager column
  {
    id: 'account_manager',
    header: () => (
      <div className="w-[100px] sm:w-[5vw] ">
        <div className="flex w-[100px] sm:w-[5vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">AM</div>
          {sortingColumn === 'account_manager' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'account_manager',
    cell: (props: any) => {
      const tooltip = `Name: ${props.getValue().name}\nHsName: ${props.getValue().hsName}`;

      return (
        <div className="flex items-center gap-x-1 justify-center w-[100px] sm:w-[5vw] text-sm font-[500] font-inter">
          <ToolTipV2
            tooltip={`${props.getValue().name === undefined ? '' : tooltip}`}
          >
            <div className="relative cursor-default">
              <ImageIcon
                data={props.getValue().name || ''}
                size={7}
                textSize={'xs'}
                customStyle="bg-[#F3F7FA] text-[#7C7E7F] font-inter font-[600] border border-gray-200 rounded-full"
              />
              {props.getValue().name !== 'N/A' ? (
                <div
                  className={`absolute bottom-0 right-0 w-2.5 h-2.5 rounded-lg border border-white
                      bg-${props.getValue().color}-500
                    `}
                />
              ) : null}
            </div>
          </ToolTipV2>
        </div>
      );
    },
  },
  // consultant column
  {
    id: 'consultant',
    header: () => (
      <div className="w-[100px] sm:w-[5vw] ">
        <div className="flex w-[100px] sm:w-[5vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Cons</div>
          {sortingColumn === 'consultant' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'consultant',
    cell: (props: any) => {
      const tooltip = `Name: ${props.getValue().name}\nHsName: ${props.getValue().hsName}`;

      return (
        <div className="flex items-center gap-x-1 justify-center w-[100px] sm:w-[5vw] text-sm font-[500] font-inter">
          <ToolTipV2
            tooltip={`${props.getValue().name === undefined ? '' : tooltip}`}
          >
            <div className="relative cursor-default">
              <ImageIcon
                data={props.getValue().name || ''}
                size={7}
                textSize={'xs'}
                customStyle="bg-[#F3F7FA] text-[#7C7E7F] font-inter font-[600] border border-gray-200 rounded-full"
              />
              {props.getValue().name !== 'N/A' ? (
                <div
                  className={`absolute bottom-0 right-0 w-2.5 h-2.5 rounded-lg border border-white
                    bg-${props.getValue().color}-500
                  `}
                />
              ) : null}
            </div>
          </ToolTipV2>
        </div>
      );
    },
  },
  // start date column
  {
    id: 'start_date',
    header: () => (
      <div className="w-[160px] sm:w-[8vw] ">
        <div className="flex w-[160px] sm:w-[8vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Start Date </div>
          {sortingColumn === 'start_date' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'startDate',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[8vw] ">
        <div className="text-sm flex justify-center font-inter font-[400] text-[#001C44] dark:text-gray-300">
          {moment(props?.getValue())?.format('MMM-YYYY')}
        </div>
      </div>
    ),
  },
  // performance column
  {
    id: 'performance',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Performance</div>
          {sortingColumn === 'performance' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'performance',
    cell: (props: any) => {
      const displayPercentage = props.getValue().value;

      let arrowColor;

      const handleDeltaType = () => {
        if (Number(displayPercentage) >= 75) {
          arrowColor = '#3CCB7F';
          return 'ArrowUpLeft';
        }
        if (Number(displayPercentage) >= 50) {
          arrowColor = '#FF692E';
          return 'ArrowRight';
        } else {
          arrowColor = '#FF0000';
          return 'ArrowDownLeft';
        }
      };

      return (
        <div className="flex flex-col items-center justify-center font-inter font-[600] w-[160px] sm:w-[12vw] ">
          <div
            className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
          >
            <Icon name={handleDeltaType()} size={14} color={arrowColor} />
            <div className={`text-[${arrowColor}]`}>
              {formatNumber(displayPercentage)}%
            </div>
          </div>
          <ToolTipV2 tooltip={props.getValue().tooltip}>
            <div className="text-xs mt-1 cursor-pointer font-light text-[#405573] dark:text-gray-300">
              {props.getValue().numberOfpositive} out of{' '}
              {props.getValue().outOf}
            </div>
          </ToolTipV2>
        </div>
      );
    },
  },
  // organic revenue column
  {
    id: 'ga4.organic_revenue',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Organic Revenue</div>
          {sortingColumn === 'ga4.organic_revenue' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'organicRevenue',
    cell: (props: any) => {
      const category = ['Organic Revenue'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      if (props.getValue()?.type === 'Lead Gen') {
        return (
          <div className="w-[160px] sm:w-[12vw] flex justify-center items-center ">
            <Badge
              className={`cursor-pointer h-[19px] text-sm p-[6px] text-[#405573] text-sm font-inter font-[600] border-none`}
            >
              N/A
            </Badge>
          </div>
        );
      }
      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="Organic Revenue"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },
  // organic conversions column
  {
    id: 'ga4.organic_conversions',
    header: () => (
      <div className="w-[180px] sm:w-[12vw]">
        <div className="flex w-[180px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Organic Conversions</div>
          {sortingColumn === 'ga4.organic_conversions' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'organicConversions',
    cell: (props: any) => {
      const category = ['Organic Conversions'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="Organic Conversions"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },
  // organic new users column
  {
    id: 'ga4.organic_new_users',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Organic Users</div>
          {sortingColumn === 'ga4.organic_new_users' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'organicUsers',
    cell: (props: any) => {
      const category = ['Organic Users'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="Organic Users"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },
  // new users column
  {
    id: 'ga4.new_users',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">New Users</div>
          {sortingColumn === 'ga4.new_users' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'newUsers',
    cell: (props: any) => {
      const category = ['New Users'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="New Users"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },
  // Clicks column
  {
    id: 'gsc.clicks',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Clicks</div>
          {sortingColumn === 'gsc.clicks' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'clicks',
    cell: (props: any) => {
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      const category = ['Clicks'];
      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="Clicks"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },
  // Impressions column
  {
    id: 'gsc.impressions',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Impressions</div>
          {sortingColumn === 'gsc.impressions' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'impressions',
    cell: (props: any) => {
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      const category = ['Impressions'];
      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="Impressions"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },
  // ahrefs top 10 keywords reach column
  {
    id: 'ahrefs.top_10_keywords_reach',
    header: () => (
      <div className="w-[160px] sm:w-[12vw]">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter flex gap-x-1">
            <Icon name="ahref" />
            (Top 10 Pages)
          </div>
          {sortingColumn === 'ahrefs.top_10_keywords_reach' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'ahrefs_top_10',
    cell: (props: any) => {
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      return (
        <div className="w-[160px] sm:w-[12vw] ">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {
              <div
                className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
              >
                <Icon name={deltaType} size={14} color={color} />
                <div className={`text-[${color}]`}>
                  {props.getValue()?.current}
                </div>
              </div>
            }{' '}
            <MetricView
              color={color}
              hasDollar={false}
              previous={props.getValue()?.previous}
            />
          </div>
        </div>
      );
    },
  },
  // ahrefs top 1 keywords reach column
  {
    id: 'ahrefs.top_1_keywords_reach',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter flex gap-x-1">
            <Icon name="ahref" />
            (Top Page)
          </div>
          {sortingColumn === 'ahrefs.top_1_keywords_reach' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'ahrefs_top_01',
    cell: (props: any) => {
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      return (
        <div className="w-[160px] sm:w-[12vw] ">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            <div
              className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
            >
              <Icon name={deltaType} size={14} color={color} />
              <div className={`text-[${color}]`}>
                {props.getValue()?.current}
              </div>
            </div>
            <MetricView
              color={color}
              hasDollar={false}
              previous={props.getValue()?.previous}
            />
          </div>
        </div>
      );
    },
  },
  // ahrefs top 3 keywords reach column
  {
    id: 'ahrefs.top_3_keywords_reach',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter flex gap-x-1">
            <Icon name="ahref" />
            (Top 3 Spots)
          </div>
          {sortingColumn === 'ahrefs.top_3_keywords_reach' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'ahrefsTop03',
    cell: (props: any) => {
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      return (
        <div className="w-[160px] sm:w-[12vw] ">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            <div
              className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
            >
              <Icon name={deltaType} size={14} color={color} />
              <div className={`text-[${color}]`}>
                {props.getValue()?.current}
              </div>
            </div>
            <MetricView
              color={color}
              hasDollar={false}
              previous={props.getValue()?.previous}
            />
          </div>
        </div>
      );
    },
  },

  // ahrefs referring domains column
  {
    id: 'ahrefs.refdomains',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Referring Domains</div>
          {sortingColumn === 'ahrefs.refdomains' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'referring_domain',
    cell: (props: any) => {
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );
      return (
        <div className="w-[160px] sm:w-[12vw] ">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            <div
              className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
            >
              <Icon name={deltaType} size={14} color={color} />
              <div className={`text-[${color}]`}>
                {props.getValue()?.current}
              </div>
            </div>
            <MetricView
              color={color}
              hasDollar={false}
              previous={props.getValue()?.previous}
            />
          </div>
        </div>
      );
    },
  },
  // return coversions column
  {
    id: 'return.conversions',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <Tooltip
          content={getColumnTitle('return.conversions')}
          position="bottom"
        >
          <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Cost Per Lead</div>
            {sortingColumn === 'return.conversions' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </Tooltip>
      </div>
    ),
    accessorKey: 'conversions_return',
    cell: (props: any) => {
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.previous,
        props.getValue()?.current
      );
      return (
        <div className="w-[160px] sm:w-[12vw] ">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            <div
              className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
            >
              <Icon name={deltaType} size={14} color={color} />
              <div className={`text-[${color}]`}>
                ${props.getValue()?.current}
              </div>
            </div>
            <MetricView
              color={color}
              hasDollar={false}
              previous={props.getValue()?.previous}
            />
          </div>
        </div>
      );
    },
  },
  // ecommerce return column
  {
    id: 'return.ecommerce',
    header: () => (
      <div className="w-[160px] sm:w-[12vw]">
        <Tooltip content={getColumnTitle('return.ecommerce')} position="bottom">
          <div className="flex w-[160px] sm:w-[12vw] justify-center gap-2 cursor-pointer">
            <div className="text-sm font-inter">Ecommerce Return</div>
            {sortingColumn === 'return.ecommerce' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </Tooltip>
      </div>
    ),
    accessorKey: 'ecommerce_return',
    cell: (props: any) => {
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.previous,
        props.getValue()?.current
      );

      if (props.getValue()?.type === 'Lead Gen') {
        return (
          <div className="w-[160px] sm:w-[12vw] flex justify-center items-center ">
            <Badge
              className={`cursor-pointer h-[19px] text-sm p-[6px] text-[#405573] font-inter font-[600] border-none`}
            >
              N/A
            </Badge>
          </div>
        );
      }

      return (
        <div className="w-[160px] sm:w-[12vw] ">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            <div
              className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
            >
              <Icon name={deltaType} size={14} color={color} />
              <div className={`text-[${color}]`}>
                ${props.getValue()?.current}
              </div>
            </div>
            <MetricView
              color={color}
              hasDollar={false}
              previous={props.getValue()?.previous}
            />
          </div>
        </div>
      );
    },
  },

  // Actions column
  {
    id: 'Action',
    header: () => (
      <div className="flex justify-center w-[100px] sm:w-[8vw] text-sm font-inter">
        Action
      </div>
    ),
    accessorKey: 'actions',
    cell: (cell: any) => (
      <div className="flex space-x-3 items-center  w-[100px] sm:w-[8vw] justify-center ">
        <div
          className="cursor-pointer"
          onClick={() => {
            editClientPerformance(cell?.row.original);
          }}
        >
          <Icon name="edit" />
        </div>
        {integration?.value !== 'missing_integration' ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              handleRegeneratePerformance(
                cell?.row.original?.client,
                'seo_google'
              );
              console.log(cell?.row.original?.client);
            }}
            title="Generate Performance"
          >
            <Icon name="RefreshIcon" size={28} color="#7C7E7F" />
          </div>
        ) : null}
      </div>
    ),
  },
];
